// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authentication-js": () => import("./../../../src/pages/authentication.js" /* webpackChunkName: "component---src-pages-authentication-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-customization-js": () => import("./../../../src/pages/customization.js" /* webpackChunkName: "component---src-pages-customization-js" */),
  "component---src-pages-hospitality-js": () => import("./../../../src/pages/hospitality.js" /* webpackChunkName: "component---src-pages-hospitality-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lebefane-js": () => import("./../../../src/pages/lebefane.js" /* webpackChunkName: "component---src-pages-lebefane-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-optout-js": () => import("./../../../src/pages/optout.js" /* webpackChunkName: "component---src-pages-optout-js" */),
  "component---src-pages-our-customers-js": () => import("./../../../src/pages/our-customers.js" /* webpackChunkName: "component---src-pages-our-customers-js" */),
  "component---src-pages-our-integrations-js": () => import("./../../../src/pages/our-integrations.js" /* webpackChunkName: "component---src-pages-our-integrations-js" */),
  "component---src-pages-plug-and-play-js": () => import("./../../../src/pages/plug-and-play.js" /* webpackChunkName: "component---src-pages-plug-and-play-js" */),
  "component---src-pages-riccione-js": () => import("./../../../src/pages/riccione.js" /* webpackChunkName: "component---src-pages-riccione-js" */),
  "component---src-pages-tracking-js": () => import("./../../../src/pages/tracking.js" /* webpackChunkName: "component---src-pages-tracking-js" */),
  "component---src-pages-who-its-for-js": () => import("./../../../src/pages/who-its-for.js" /* webpackChunkName: "component---src-pages-who-its-for-js" */),
  "component---src-pages-why-cau-js": () => import("./../../../src/pages/why-CAU.js" /* webpackChunkName: "component---src-pages-why-cau-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

