import React, { useContext } from "react";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { ThemeContext } from "../../context/ThemeContext";

import "./style.scss";

const Menu = ({ open }) => {
  const theme = useContext(ThemeContext);

  function closeMenu() {
    theme.setMenuOpen(false);
  }

  const MENUITEMS = [
    { item: "A chi è rivolto", to: "/a-chi-è-rivolto", externalLink: false, mobileMenuItem: false, },
    { item: "Le nostre integrazioni", to: "/le-nostre-integrazioni", externalLink: false, mobileMenuItem: false, },
    { item: "Perché Connect@You", to: "/perch%C3%A8-connect-at-you", externalLink: false, mobileMenuItem: false, },
    { item: "Clienti", to: "/i-nostri-clienti", externalLink: false, mobileMenuItem: false, },
    { item: "Shop", to: "/shop", externalLink: true, mobileMenuItem: false, },
    { item: "Blog & News", to: "/blog", externalLink: false, mobileMenuItem: false, },
    { item: "Contatti", to: "/contatti", externalLink: false, mobileMenuItem: false, },
    { item: "Login", to: "https://admin.connectatyou.it/login", externalLink: true, mobileMenuItem: true, },
  ];

  const variants = {
    menuAnimation: {
      open: { width: theme.isMobile ? "100%" : "35%", transition: { duration: 0.3 } },
      closed: { width: "0px", transition: { delay: 0.2, duration: 0.5 } },
    },
    menuItemAnimation: {
      open: { x: 0, opacity: 1 },
      closed: { x: 100, opacity: 0, transition: { delay: 0.1, duration: 0.2 } },
    },
  };

  const menuItem = (i, index) => (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={variants.menuItemAnimation}
      transition={{ delay: 0.1 * index, duration: 0.3 }}
      className="menuItem"
    >
      {i.externalLink ? (
        <a target="_blank" rel="noreferrer" href={i.to}>
          {i.item}
        </a>
      ) : (
        <Link to={i.to} onClick={closeMenu}>
          {i.item}
        </Link>
      )}
    </motion.div>
  );

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={variants.menuAnimation}
      className="menu"
    >
      {MENUITEMS.map((i, index) => (
        <AnimatePresence exitBeforeEnter key={index}>
          {!i.mobileMenuItem && menuItem(i, index)}
          {theme.isMobile && i.mobileMenuItem && menuItem(i, index)}
        </AnimatePresence>
      ))}
    </motion.div>
  );
};

export default Menu;
