import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

function ThemeProvider(props) {
  const [NETWORKS, setNETWORKS] = useState([
    { id: 1, name: "", featured: false, lat: "", long: "", logo: "" },
  ]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  const STRAPI_LOGOS = useStaticQuery(graphql`
    query {
      data: allStrapiCauLogosNetworks {
        edges {
          node {
            id_rete
            image {
              publicURL
            }
          }
        }
      }
    }
  `);

  const [width, setWidth] = useState();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('load', handleWindowSizeChange);
    window.addEventListener("resize", handleWindowSizeChange);    
  }, []);

  let isMobile = width <= 1024;

   // Overwrites the networks logos with the Strapi logos that have the same id
    STRAPI_LOGOS.data.edges.map(i =>
      NETWORKS.map(j =>
        j.id === parseInt(i.node.id_rete) ? (j.logo = i.node.image.publicURL) : null
      )
    );

  useEffect(() => {
    window
      .fetch("https://api.connectatyou.it/networks")
      .then((response) => response.json())
      .then((jsonData) => setNETWORKS(jsonData.data))
      .catch((error) => console.error("Errore: " + error));     
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const stateToPublic = {
    NETWORKS,
    isMobile,
    menuOpen,
    setMenuOpen,
    currentYear,
    setCurrentYear
  };

  return (
    <ThemeContext.Provider value={stateToPublic}>
      {props.children}
    </ThemeContext.Provider>
  );
}

const ThemeContext = React.createContext({});

export { ThemeContext, ThemeProvider };
