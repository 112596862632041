import { Link } from "gatsby";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import LogoCambium from "../../assets/img/svg/logo-cambium.svg"
import LogoAruba from "../../assets/img/svg/logo-aruba.svg"
import "./style.scss";

const Footer = () => {
  const theme = useContext(ThemeContext)
  
  return (
    <footer>
      <div className="inner-footer">
        <div className="columns is-multiline m-0">
          <div className="column is-offset-1-tablet is-2-tablet contacts">
            <Link to="/a-chi-è-rivolto">A chi è rivolto</Link>
            <br />
            <br />
            <Link to="/le-nostre-integrazioni">Le nostre integrazioni</Link>
            <br />
            <br />
            <Link to="/perch%C3%A8-connect-at-you">Perchè Connect@You</Link>
            <br />
            <br />
            <a target="_blank" rel="noreferrer" href="/privacy.html">Privacy Policy</a>
          </div>
          <div className="column is-2-tablet pages">
            <Link to="/i-nostri-clienti">Clienti</Link>
            <br />
            <br />
            <a target="_blank" rel="noreferrer" href="/shop">Shop</a>
            <br />
            <br />
            <Link to="/blog">Blog &amp; News</Link>
            <br />
            <br />
            <a target="_blank" rel="noreferrer" href="/cookie.html">Cookie Policy</a>
          </div>
          <div className="column is-2-tablet social">
            <Link to="/contatti">Contatti</Link>
            <br />
            <br />
            <a target="_blank" rel="noreferrer" href="https://admin.connectatyou.it/login">Login</a>
          </div>
          <div className="column is-offset-2-tablet is-offset-2-desktop is-8-tablet is-3-desktop">
            <div className="columns is-gapless">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/acmeproduzioni/"
                className="column is-3 is-full-mobile"
              >
                <span className="azure">Facebook</span>
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/acmeproduzioni_official/"
                className="column is-9 is-full-mobile"
              >
                <span className="azure">Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="columns m-0">
          <div className="column is-offset-1-tablet is-8-tablet ">
            <span>© 2013-{theme.currentYear} Connect@You® by ACME Produzioni Srl - PIVA/VAT 10245151005</span>
          </div>
          <div className="column is-2-tablet ">
            <div className="columns">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.cambiumnetworks.com/"
                className=""
              >
                <img src={LogoCambium} alt="Logo Cambium" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.arubanetworks.com/it"
                className=""
              >
                <img src={LogoAruba} alt="Logo Aruba" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
