import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Menu from "../menu/menu.js";
import { ThemeContext } from "../../context/ThemeContext";
import { motion } from "framer-motion";

import "./style.scss";

const Navbar = () => {
  const theme = useContext(ThemeContext);
  const [scrolled, setScrolled] = useState(false);

  function toggleMenu() {
    theme.setMenuOpen(!theme.menuOpen);
  }

  function closeMenu() {
    theme.setMenuOpen(false);
  }

  function handleScroll() {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <header className={scrolled ? "scrolled" : ""}>
      <Menu open={theme.menuOpen} />
      <div className="background">
        <div className="container is-widescreen">
          <div className="inner-header">
            <Link to="/" onClick={closeMenu}>
              <img className="logo" src="https://static.acmeproduzioni.it/connectatyou/logo.svg" alt="Connect@You - Logo" />
            </Link>
            <div className="menuButtons">
              <button className="menuButton" onClick={toggleMenu}>
                <div className="menuButtonText">
                  {theme.menuOpen ? <>Chiudi</> : <>Menu</>}
                </div>
              </button>
              <div className="menuButton" id="loginButton">
                <div className="menuButtonText">
                <a target="_blank" rel="noreferrer" href="https://admin.connectatyou.it/login">Login</a>
                </div>
              </div>
              <button
                className="menuMobileIcon"
                onClick={toggleMenu}
                aria-label="Toggle Menu"
              >
                <motion.div
                  animate={
                    theme.menuOpen
                      ? { transform: "rotate(45deg)" }
                      : { transform: "rotate(0deg)" }
                  }
                ></motion.div>
                <motion.div
                  animate={theme.menuOpen ? { opacity: 0 } : { opacity: 1 }}
                  transition={{ duration: 0.1 }}
                ></motion.div>
                <motion.div
                  animate={
                    theme.menuOpen
                      ? { transform: "rotate(-45deg)" }
                      : { transform: "rotate(0deg)" }
                  }
                ></motion.div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
